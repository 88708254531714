<template>
	<div>
		<el-tabs type="border-card">
		  <el-tab-pane label="我的歌曲">
			  <el-table :data="tableData" style="width: 100%">
			  	<el-table-column prop="id" label="ID" width="100">
			  		<template slot-scope='scope'>
			  			<span style="color:rgb(223 157 10);font-weight:700;">{{scope.row.id}}</span>
			  		</template>
			  	</el-table-column>
			  	<el-table-column prop="title" label="歌曲名" width="120">
			  		<template slot-scope='scope'>
			  			<span style="color:#383232;font-weight: 600;">{{scope.row.title}}</span>
			  		</template>
			  	</el-table-column>
			  	<el-table-column prop="pic_cover" label="歌曲封面">
			  		<template slot-scope='scope'>
			  			<el-image style="width: 80px; height: 80px" :src="scope.row.pic_cover" fit="fill"
			  				:preview-src-list="[scope.row.pic_cover]">
			  			</el-image>
			  		</template>
			  	</el-table-column>
			  	<el-table-column prop="artist" label="歌手" width='120'>
			  		<template slot-scope='scope'>
			  			<span style="color: #20B2AA;">{{scope.row.artist}}</span>
			  		</template>
			  	</el-table-column>
			  	<el-table-column prop="music_url" label="音频地址">
			  		<template slot-scope='scope'>
			  			<span class="des">{{scope.row.music_url}}</span>
			  		</template>
			  	</el-table-column>
			  	<el-table-column prop="lrc" label="歌词文件">
			  		<template slot-scope='scope'>
			  			<span class="des" style="color: #e91e63;">{{scope.row.lrc}}</span>
			  		</template>
			  	</el-table-column>
			  	<el-table-column label="操作">
			  		<template slot-scope='scope'>
			  			<el-button type="primary" icon="el-icon-edit" circle size='mini' @click='editCurrentRow(scope.row)'>
			  			</el-button>
			  			<el-button type="danger" icon="el-icon-delete" circle size='mini'
			  				@click='deleteCurrentRow(scope.row.id)'></el-button>
			  		</template>
			  	</el-table-column>
			  </el-table>
			  <el-pagination @current-change="handleCurrentChange" :page-size="10" layout="total,prev, pager, next, jumper"
			  		:total="total">
			  </el-pagination>
		  </el-tab-pane>
		  <el-tab-pane label="添加歌曲">
		     <el-form ref="form" :model="form" label-width="80px">
		       <el-form-item label="歌曲名">
		         <el-input v-model="form.name" clearable></el-input>
		       </el-form-item>
		       <el-form-item label="歌曲封面">
		         <el-input v-model="form.cover" placeholder="请以http或者https开头" clearable></el-input>
		       </el-form-item>
			   <el-form-item label="歌手">
			     <el-input v-model="form.artist" clearable></el-input>
			   </el-form-item>
			   <el-form-item label="音频地址">
			     <el-input v-model="form.address"placeholder="请以http或者https开头" clearable></el-input>
			   </el-form-item>
			   <el-form-item label="歌词文件">
			     <el-input v-model="form.lrc" clearable></el-input>
			   </el-form-item>
		     </el-form>
			  <el-alert
			     title="歌曲封面与音频地址,均使用阿里云OSS对象存储"
			     type="warning"
			  >
			   </el-alert>
			 <el-button type="primary" style='width: 100%;height: 40px;margin-top: 20px;'@click='submitRelease'>提交并发布</el-button>
		  </el-tab-pane>
	    </el-tabs>
		<el-dialog title="歌曲编辑" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>
				<el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign">
					<el-form-item label="歌曲名">
						<el-input v-model="formLabelAlign.title"></el-input>
					</el-form-item>
					<el-form-item label="歌曲封面">
						<el-input v-model="formLabelAlign.pic_cover"></el-input>
					</el-form-item>
					<el-form-item label="歌手">
						<el-input v-model="formLabelAlign.artist"></el-input>
					</el-form-item>
					<el-form-item label="音频地址">
						<el-input v-model="formLabelAlign.music_url"></el-input>
					</el-form-item>
					<el-form-item label="歌词文件">
						<el-input v-model="formLabelAlign.lrc"></el-input>
					</el-form-item>
				</el-form>
			</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="ConfirmModification">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		adminMyMusicInitData,
		adminMyMusicDelete,
		adminMyMusicAlter,
		adminMyMusicAdd
	} from '../../../server/api/index.js'
	import {
		getUserInfo
	} from '../../../server/api/store.js'
	export default {
		data() {
			return {
				tableData: [],
				total: null,
				currentPage: 1,
				dialogVisible: false,
				labelPosition: 'left',
				formLabelAlign: {},
				form:{
					name:'',
					cover:'',
					artist:'',
					address:'',
					lrc:'',
				}
			}
		},
		methods: {
			async handleCurrentChange(val) {
				this.currentPage = val;
				let ref = await adminMyMusicInitData({
					page: val
				});
				if (ref.code === 200) {
					this.tableData = ref.data;
					this.total = ref.total;
				}
			},
			async initData(page) {
				let ref = await adminMyMusicInitData({
					page
				});
				if (ref.code === 200) {
					this.tableData = ref.data;
					this.total = ref.total;
				}
			},
			editCurrentRow(data) {
				this.formLabelAlign = data;
			    this.dialogVisible = true;
			},
			ConfirmModification(){
				this.$confirm('确定要修改吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async ()=>{
					let ref = await adminMyMusicAlter({data:this.formLabelAlign,account:this.account});
					if(ref.code===200){
						this.$message.success(ref.msg);
						this.dialogVisible = false;
						this.initData(this.currentPage);
					}else{
						this.$message.error(ref.msg);
					}
				}).catch((err)=>{
					//this.$message.info(err);
				})
			},
			deleteCurrentRow(id) {
				this.$confirm('确定要删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let ref = await adminMyMusicDelete({
						id,
						account: this.account
					});
					if (ref.code === 200) {
						this.$message.success(ref.msg);
						this.initData(this.currentPage);
					} else {
						this.$message.error(ref.msg);
					}
				}).catch((err) => {
					//this.$message.info(err);
				});
			},
			handleClose() {
				this.dialogVisible = false;
			},
			submitRelease(){
				this.$nextTick(async ()=>{
					if(this.form.name==='') return this.$message.error('歌曲名不能为空');
					if(this.form.cover==='') return this.$message.error('封面不能为空');
					if(this.form.artist==='') return this.$message.error('歌手名不能为空');
					if(this.form.address==='') return this.$message.error('音频地址不能为空');
					if(this.form.lrc==='') return this.$message.error('歌词文件不能为空');
					this.form.account = this.account;
					let ref = await adminMyMusicAdd(this.form);
					if(ref.code===200){
						this.$message.success(ref.msg);
						this.form = {};
					}else{
						this.$message.error(ref.msg);
					}
				})
			}
		},
		mounted() {
			this.initData(this.currentPage); //页面初始化
		},
		computed: {
			account() {
				return getUserInfo().account;
			}
		}
	}
</script>

<style scoped="scoped">
	/deep/ .el-table th>.cell {
		text-align: center;
		font-size: 12px;
	}
	.des {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
		font-weight: 500;
		font-size: 12px;
		color: black;
	}

	/deep/.el-table td,
	.el-table th.is-leaf {
		text-align: center;
		font-size: 12px;
	}
	/deep/ .el-table {
		top: -20px;
	}
</style>
