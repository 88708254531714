<template>
	<div>
		<el-card>
			<el-tabs v-model="activeName" @tab-click="handleClick" v-loading='loading'>
				<el-tab-pane label="已通过友链" name="first">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="id" label="id" width='70'>
							<template slot-scope='scope'>
								<span class="ids">{{scope.row.id}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="website" label="网站名称">
						</el-table-column>
						<el-table-column prop="websiteLogo" label="网站logo">
							<template slot-scope='scope'>
								<el-avatar size="large" :src="scope.row.websiteImage"></el-avatar>
							</template>
						</el-table-column>
						<el-table-column prop="websiteUrl" label="网站地址">
							<template slot-scope='scope'>
								<el-link type="success" style='font-size: 10px;font-weight: 400;'
									:href='scope.row.websiteUrl' target="_blank">
									{{scope.row.websiteUrl}}
								</el-link>
							</template>
						</el-table-column>
						<el-table-column prop="webDescription" label="网站描述" width='180'>
						</el-table-column>
						<el-table-column prop="isPass" label="是否通过">
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope='scope'>
								<el-button type="primary" size='mini' @click='handleJunmp(0,scope.row)'>修改</el-button>
								<el-button type="danger" size='mini' @click='handleDeleteFriendLink("pass",scope.row.id)'>删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="未通过友链" name="seccond">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="id" label="id" width='70'>
							<template slot-scope='scope'>
								<span class="ids">{{scope.row.id}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="website" label="网站名称">
						</el-table-column>
						<el-table-column prop="websiteLogo" label="网站logo">
							<template slot-scope='scope'>
								<el-avatar size="large" :src="scope.row.websiteImage"></el-avatar>
							</template>
						</el-table-column>
						<el-table-column prop="websiteUrl" label="网站地址">
							<template slot-scope='scope'>
								<el-link type="success" style='font-size: 10px;font-weight: 400;'
									:href='scope.row.websiteUrl' target="_blank">
									{{scope.row.websiteUrl}}
								</el-link>
							</template>
						</el-table-column>
						<el-table-column prop="webDescription" label="网站描述" width='180'>
						</el-table-column>
						<el-table-column prop="isPass" label="是否通过">
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope='scope'>
								<el-button type="danger" size='mini' @click='handleDeleteFriendLink("unpass",scope.row.id)'>删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="待处理友链" name="third">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="id" label="id" width='70'>
							<template slot-scope='scope'>
								<span class="ids">{{scope.row.id}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="websitename" label="网站名称">
						</el-table-column>
						<el-table-column prop="websiteLogo" label="网站logo">
							<template slot-scope='scope'>
								<el-avatar size="large" :src="scope.row.wensitelogo"></el-avatar>
							</template>
						</el-table-column>
						<el-table-column prop="websiteurl" label="网站地址">
							<template slot-scope='scope'>
								<el-link type="success" style='font-size: 10px;font-weight: 400;'
									:href='scope.row.websiteurl' target="_blank">
									{{scope.row.websiteurl}}
								</el-link>
							</template>
						</el-table-column>
						<el-table-column prop="websitedes" label="网站描述" width='180'>
						</el-table-column>
						<el-table-column prop="websiteemail" label="QQ邮箱" width='180'>
						</el-table-column>
						<el-table-column prop="user" label="申请人(昵称)" width='180'>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope='scope'>
								<el-button type="primary" size='mini' @click='handleJudge("pass",scope.row)'>通过</el-button>
								<el-button type="danger" size='mini' @click='handleJudge("refuse",scope.row)'>拒绝</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
			<el-pagination @current-change="handleCurrentChange" :page-size="10"
				layout="total,prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
		<div class="container" index='01'>
			<h1>友链修改</h1>
			<div style="margin-top: 15px;">
				<span>友链名称:</span>
				<el-input v-model='editeTable.website' style='width: 250px;' maxlength='15' clearable size='mini'></el-input>
			</div>
			<div style="margin-top: 15px;">
				<span>友链地址:</span>
				<el-input v-model='editeTable.websiteUrl' style='width: 250px;' maxlength='30' clearable size='mini'></el-input>
			</div>
			<div style="margin-top: 15px;">
				<span>友链描述:</span>
				<el-input v-model='editeTable.webDescription' style='width: 250px;' maxlength='20' clearable size='mini'></el-input>
			</div>
			<div style="margin-top: 15px;">
				<span>友链头像:</span>
				<el-input v-model='editeTable.websiteImage' style='width: 250px;' maxlength='100' clearable size='mini'></el-input>
			</div>
			<el-button type='primary' size='mini' @click='updateFriendLink'>更新友链</el-button>
		</div>
		<div class="container" index='02'>
			<h1>手动添加友链</h1>
			<div style="margin-top: 15px;">
				<span>友链名称:</span>
				<el-input v-model='writeTable.website_name' style='width: 250px;' maxlength='15' clearable size='mini'></el-input>
			</div>
			<div style="margin-top: 15px;">
				<span>友链地址:</span>
				<el-input v-model='writeTable.website_url' style='width: 250px;' maxlength='30' clearable size='mini'></el-input>
			</div>
			<div style="margin-top: 15px;">
				<span>友链描述:</span>
				<el-input v-model='writeTable.website_des' style='width: 250px;' maxlength='20' clearable size='mini'></el-input>
			</div>
			<div style="margin-top: 15px;">
				<span>友链头像:</span>
				<el-input v-model='writeTable.website_avatar' style='width: 250px;' maxlength='100' clearable size='mini'></el-input>
			</div>
			<div style="margin-top: 15px;">
				<span>QQ邮箱:</span>
				<el-input v-model='writeTable.qq_email' style='width: 250px;' maxlength='20' clearable size='mini'></el-input>
			</div>
			<el-button type='primary' size='mini' @click='addFriendLink'>添加友链</el-button>
		</div>
	</div>
</template>

<script>
	import {
		adminFriendLinkAdmin,
		adminFrendLinkOperation,
		adminFriendLinkDelete,
		adminFriendLinkUpdate,
		adminFriendLinkAdd
	} from '../../../server/api/index.js'
	import {
		getUserInfo
	} from '../../../server/api/store.js'; //获取用户登录信息
	export default {
		data() {
			return {
				activeName: 'first',
				tableData: [],
				val:'sssssss',
				editeTable:{},
				writeTable:{
					website_name:'',
					website_url:'',
					website_des:'',
					website_avatar:'',
					qq_email:''
				},
				total: null,
				loading: true,
				currentIndex: '0',
			};
		},
		methods: {
			handleClick(tab) {
				this.currentIndex = tab.index;
				this.initData(1, tab.index);
			},
			handleCurrentChange(val) {
				this.initData(val, this.currentIndex);
			},
			async initData(page, index) {
				let ref = await adminFriendLinkAdmin({
					page,
					index
				});
				if (ref.code === 200) {
					this.tableData = ref.data;
					this.total = ref.length;
					this.loading = false;
				}
			},
			async handleJudge(type, data) {
				this.$confirm('确认执行当前操作?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(async () => {
						let ref = await adminFrendLinkOperation({
							type,
							data,
							account:this.account
						});
						if (ref.code === 200) {
							this.$message.success(ref.msg);
							this.initData(1, '2');
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});
            },
			async handleDeleteFriendLink(type,id){
				this.$confirm('确认执行当前操作?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(async () => {
						let ref = await adminFriendLinkDelete({
							type,
							id,
							account:this.account
						});
						if (ref.code === 200) {
							this.$message.success(ref.msg);
							this.initData(1, this.currentIndex);
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});
			},
			handleJunmp (index,data) {
			    let jump = document.querySelectorAll('.container');
				let content = document.querySelector('.content');
			    // 获取需要滚动的距离
			    let total = jump[index].offsetTop; //纵向偏移量
				// Chrome
			    content.scrollTop = total  //纵向滚动量的赋值
			    // Firefox
			    content.scrollTop = total
			    // Safari
			    content.pageYOffset = total
				this.editeTable = data;
			},
			clearEditTable(){
				let newObj={
					website:'',
					websiteUrl:'',
					webDescription:'',
					websiteImage:''
				};
				return this.editeTable = newObj;
			},
			clearWriteTable(){
				let newObj={
					website:'',
					websiteUrl:'',
					webDescription:'',
					websiteImage:''
				};
				return this.writeTable = newObj;
			},
			async updateFriendLink(){                           //更新友链
			    this.editeTable.account = this.account;
				let ref = await adminFriendLinkUpdate(this.editeTable);
				if(ref.success){
					this.$message.success(ref.msg);
					this.clearEditTable();//清空编辑框
					this.initData(1,'0'); //页面初始化
				}else{
					this.$message.error(ref.msg);
					this.clearEditTable();//清空编辑框
					this.initData(1,'0');
				}
			},
			async addFriendLink(){                             //添加友链
			    this.writeTable.account = this.account;
				let ref = await adminFriendLinkAdd(this.writeTable);
				if(ref.success){
					this.$message.success(ref.msg);
					this.clearWriteTable();
					this.initData(1,'0'); //页面初始化
				}else{
					this.$message.error(ref.msg);
					this.clearWriteTable();
					this.initData(1,'0');
				}
			}
		},
		mounted() {
			this.initData(1, '0'); //页面初始化
		},
		computed:{
			account(){
				return getUserInfo().account;
			}
		}
	};
</script>

<style scoped="scoped">
	/deep/.el-table th {
		background-color: #f5f7fa !important;
	}

	/deep/ .el-tabs__header {
		margin: unset !important;
	}

	/deep/ .el-table th>.cell {
		text-align: center;
		font-size: 12px;
		font-weight: 600;
	}

	/deep/ .container {
		text-align: center;
	}

	.container div span {
		color: #fff;
		background-color: #ff5777;
		padding: 5px 10px;
		border-radius: 4px;
		display: inline-block;
		height: 20px;
		line-height: 20px;
	}

	/deep/ .el-table .cell {
		text-align: center;
		font-size: 12px;
		font-weight: 600;
	}

	.ids {
		color: #fff;
		background-color: #ff5777;
		padding: 5px 10px;
		border-radius: 4px;
	}
</style>
