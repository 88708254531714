<template>
	<div>
		<el-table v-loading='loading' :data="tableData" border style="width: 100%">
			<el-table-column prop="id" label="id" width="60">
			</el-table-column>
			<el-table-column prop="title" label="标题" width="100">
			</el-table-column>
			<el-table-column prop="end_text" label="文章描述" width='100'>
				<template slot-scope='scope'>
					<span class="des">{{scope.row.end_text}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="Image" label="Image" width="150">
				<template slot-scope='scope'>
					<el-image :src='scope.row.img_src' :preview-src-list="[scope.row.img_src]" fit='fill'
						style='width: 100%;'></el-image>
				</template>
			</el-table-column>
			<el-table-column prop="tags" label="tags" width="100">
				<template slot-scope='scope'>
					<div class="tag" v-for="(item,index) in scope.row.tags" :key='index'>
						<span :style='item.color'>{{item.name}}</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="hot" label="Hot" width="100">
			</el-table-column>
			<el-table-column prop="remark_num" label="Comment" width="100">
			</el-table-column>
			<el-table-column prop="Gotop" label="是否页面置顶" width="70">
				<template slot-scope='scope'>
					<el-switch v-model="scope.row.is_gotop==='yes'? true:false" active-color="#13ce66" @change='handleArticleTop(scope.row.id)'
						inactive-color="#ff4949">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column prop="birth_time" label="创建时间" width="100">
			</el-table-column>
			<el-table-column prop="modify_time" label="修改时间" width="100">
			</el-table-column>
			<el-table-column prop="isRemark" label="是否开启评论" width="70">
				<template slot-scope='scope'>
					<el-switch v-model="scope.row.is_remark==='yes'? true:false" active-color="#13ce66" @change='handleArticleRemarkPower(scope.row.id,scope.row.is_remark)'
						inactive-color="#ff4949">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column prop="isCombat" label="是否实战" width="80">
				<template slot-scope='scope'>
					<el-switch v-model="scope.row.is_combat==='yes'? true:false" active-color="#13ce66" @change='handleArticleCombat(scope.row.id,scope.row.is_combat)'
						inactive-color="#ff4949">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column prop="is_original" label="是否原创" width="80">
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope='scope'>
					<el-button type="primary" size='mini' @click='handleJunmp(0,scope.row)'>编辑</el-button>
					<el-button type="danger" size='mini' style='margin-top: 5px;margin-left: 1px;'
						@click='deleteArticle(scope.row.id)'>删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handleCurrentChange" layout="total,prev, pager, next, jumper" :page-size="10"
			:total="total">
		</el-pagination>
		<div class="line"></div>
		<div class="container">
			<div class="headTitle">文章编辑更新</div>
			<div class="headTitleSon">为了保证真实性,部分信息不给予修改接口</div>
			<div class="input_item">
				<span>文章标题:</span>
				<el-input placeholder="请输入内容" v-model="editeTable.title" clearable label='标题' maxlength='20' show-word-limit>
				</el-input>
			</div>
			<div class="input_item">
				<span>文章描述:</span>
				<el-input type="textarea" placeholder="请输入内容" v-model="editeTable.end_text" clearable label='文章描述' maxlength='100'
					show-word-limit>
				</el-input>
			</div>
			<div class="input_item">
				<span>文章图片:</span>
				<el-upload 
				class="upload-demo" 
				drag 
				action
				multiple
				ref='upload'
				list-type="picture"
				name='image'
				:auto-upload="false"
				:on-remove="handleRemove" 
				:before-remove="beforeRemove"
				:limit="1" 
				:http-request='handleUploadForm'
				:on-exceed="handleExceed"
				:on-change='handleChange'
				:file-list="fileList"
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
			</div>
			<mavon-editor v-model="articleData.value" style='margin-top: 20px;min-height: 500px;' @save='handleThisSave'/>
			<el-button type='primary' style='width: 100%;height: 50px;margin-top: 20px;' @click='updateThisArticle'>更新此文章</el-button>
		</div>
	</div>
</template>

<script>
	import {
		adminArticleEdit,
		adminArticleEditPagination,
		adminArticleDelete,
		adminArticleUpate,
		adminArticleGotop,
		adminArticleRemarkPower,
		adminArticleCombat
	} from '../../../server/api/index.js';
	import {
		getUserInfo
	} from '../../../server/api/store.js'
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				total: null,
				currentPage:1,
				editeTable:{},
				fileList: [],    
				articleData:{
					value:''
				}
			}
		},
		methods: {
			handleRemove(file, fileList) {
				this.$message.warning('您已移出需要上传的图片');
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			handleUploadForm(param){                         //此函数会被 this.$refs.upload.submit() 自动触发
				let fd = new FormData()// FormData 对象
				let allObj = this.editeTable;
				this.editeTable.account = this.account;
				    fd.image = param;
					fd.append('image',param.file);
					fd.append('data',JSON.stringify(allObj));
				adminArticleUpate(fd).then(data=>{
					if(data.code===200){
						this.$message.success(data.msg);
						this.fileList = [ { name:data.name,url:data.url } ];
						this.editeTable = {};//清空输入框
					}else{
						this.$message.warning(data.msg);
					}
				}).catch(err=>console.log(err));
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			handleChange(file,fileList){
				this.fileList = fileList;
			},
			async handleCurrentChange(page) {
				let ref = await adminArticleEditPagination({
					page
				});
				this.currentPage = page;
				if (ref.code === 200) {
					this.tableData = ref.data;
					this.total = ref.length;
					this.loading = false;
					ref.data.forEach((val) => {
						val.tags = JSON.parse(val.tags).tagData; //数据类型转换
						val.tags.forEach((val) => {
							val.color = 'background-color:' + val.color; //属性值转换
						})
					});
				}
			},
			async initData() {
				let ref = await adminArticleEdit({page:this.currentPage});
				if (ref.code === 200) {
					this.tableData = ref.data;
					this.total = ref.length;
					this.loading = false;
					ref.data.forEach((val) => {
						val.tags = JSON.parse(val.tags).tagData; //数据类型转换
						val.tags.forEach((val) => {
							val.color = 'background-color:' + val.color; //属性值转换
						})
					});
				}
			},
			handleJunmp (index,data) {
			    let jump = document.querySelectorAll('.container');
				let content = document.querySelector('.content');
			    // 获取需要滚动的距离
			    let total = jump[index].offsetTop; //纵向偏移量
				// Chrome
			    content.scrollTop = total;  //纵向滚动量的赋值
			    // Firefox
			    content.scrollTop = total;
			    // Safari
			    content.pageYOffset = total;
				this.editeTable = data;
				this.articleData.value = data.markdown;
			},
			async deleteArticle(id) {
				this.$confirm('此操作将永久删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let ref = await adminArticleDelete({
						id,
						account: this.account
					});
					if (ref.success) {
						this.$message.success(ref.msg);
						this.initData(); //页面初始化
					} else {
						this.$message.error(ref.msg);
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})

			},
			handleThisSave(val,render){                           //markdown的保存
				if(val.length===0) return this.$message.error('不能为空');
				this.editeTable.markdown = val; 
				this.editeTable.article_content = render;
				this.$message.success('保存成功');
			},
			async updateThisArticle(){
				this.$nextTick(()=>{
					if(this.editeTable.title===undefined) return this.$message.error('标题不能为空');
					if(this.editeTable.end_text===undefined) return this.$message.error('描述不能为空');
					if(this.editeTable.value==='') return this.$message.error('markdown不能为空');
 					this.$refs.upload.submit();//最后进行图片与其余data数据的上传
				});
			},
			async handleArticleTop(id){           
		 		let ref = await adminArticleGotop({id,account:this.account});
				if(ref.code===200){
					this.$message.success(ref.msg);
					this.initData();
				}else{
					this.$message.error(ref.msg);
				}
			},
			async handleArticleRemarkPower(id,val){
				let ref = await adminArticleRemarkPower({id,val,account:this.account});
				if(ref.code===200){
					this.$message.success(ref.msg);
					this.initData();
				}else{
					this.$message.error(ref.msg);
				}
			},
			async handleArticleCombat(id,val){
				let ref = await adminArticleCombat({id,val,account:this.account});
				if(ref.code===200){
					this.$message.success(ref.msg);
					this.initData();
				}else{
					this.$message.error(ref.msg);
				}
			}
		},
		mounted() {
			this.initData();
		},
		computed: {
			account() {
				return getUserInfo().account;
			}
		}
	}
</script>

<style scoped="scoped">
	/deep/ .el-table th>.cell {
		text-align: center;
	}

	/deep/ .el-table th {
		background-color: #f5f7fa !important;
		font-size: 12px;
		font-weight: 600;
		color: gray;
	}

	/deep/ .el-pagination__jump {
		margin-left: 990px;
	}

	.line {
		height: 4px;
		border: 0;
		background-color: #ddd;
		margin: 20px 0;
		background-image: repeating-linear-gradient(-45deg, #fff, #ff5777 5px, transparent 0, transparent 8px);
	}

	.container {
		text-align: center;
	}

	.container .headTitle {
		font-size: 20px;
		font-weight: 600;
	}

	.container .headTitleSon {
		font-size: 14px;
		font-weight: 400;
		color: gray;
	}

	.content {
		display: flex;
	}

	.input_item {
		padding-left: 0px;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-top: 10px;
	}

	.input_item span {
		font-weight: 700;
		width: 100px;
		font-size: 20px;
	}

	/deep/.el-upload-dragger {
		left: -6px;
	}

	/deep/.el-table .cell {
		font-weight: 600;
		font-size: 12px;
		text-align: center;
	}

	.des {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
		font-weight: 500;
		font-size: 12px;
		color: black
	}

	.tag {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.tag span {
		color: #fff;
		border-radius: 5px;
		margin-top: 5px;
		display: inline-block;
		width: 90px;
	}
</style>
