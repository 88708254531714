<template>
	<div>
		<mavon-editor v-model="articleData" style='margin-top: 20px;min-height: 500px;' @save='saveInfo'/>
		<el-button type='primary' style='width: 100%;height: 50px;margin-top: 20px;' @click='handleSubmit'>提交并发布</el-button>
	</div>
</template>

<script>
import {
		adminMyAboutMe,
		adminMyAboutMeinit
} from '../../../server/api/index.js';
import {
		getUserInfo
} from '../../../server/api/store.js'
export default{
	data() {
		return{
			articleData:'',
			isSubmit:false,
			html:'',
			markdown:'',
		}
	},
	methods:{
		saveInfo(value,render){
			if(render.length===0) return this.$myMessage({msg:'长度必须大于0',icon:'el-icon-error',color:'color:red;',duration:1000});
			this.$myMessage({msg:'数据保存成功!',icon:'el-icon-success',color:'color:green;',duration:2000});
			this.isSubmit = true;
			this.html = render;
			this.markdown = value;
		},
		async handleSubmit(){
			if(this.isSubmit){
				let ref = await adminMyAboutMe({htmlStr:this.html,markdown:this.markdown,account:this.account});
				if(ref.code===200){
					this.articleData = '';
					this.$myMessage({msg:ref.msg,icon:'el-icon-success',color:'color:green;',duration:2000});
				}else{
					this.$myMessage({msg:ref.msg,icon:'el-icon-error',color:'color:red;',duration:2000});
				}
			}else{
				this.$myMessage({msg:'数据未保存!',icon:'el-icon-error',color:'color:red;',duration:3000});
			}
		},
		async initial(){
			let ref = await adminMyAboutMeinit();
			if(ref.code===200){
			    this.articleData = ref.data[0].markdown;
			}
		}
	},
	mounted() {
		this.initial();
	},
	computed: {
		account() {
			return getUserInfo().account;
		}
	}
}
</script>

<style scoped="scoped">
	
</style>
